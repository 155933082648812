@import '@styles/mixins.style';

.acf-inspiration-blocks {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--ra-spacing-4);

  @include breakpoint(sm) {
    grid-template-columns: repeat(3, 1fr);
  }
}
